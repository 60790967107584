import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterProp',
  pure: false,
})
export class FilterPropPipe implements PipeTransform {
  transform<T extends Record<string, any>>(
    items: T[],
    property: keyof T,
    value: any,
  ): T[] {
    if (!items?.length || !property) return [];
    if (value === undefined) return items;
    return items.filter((item) => item[property] === value);
  }
}

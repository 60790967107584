import { TokenDecoded } from 'src/app/store/auth/auth.model';
import jwt_decode from 'jwt-decode';
import { HttpHeaders } from '@angular/common/http';

export const tokenExpired = (): boolean => {
  const exp = tokenDecoded()?.exp;
  return Math.floor(new Date().getTime() / 1000) >= exp;
};
export const tokenDecoded = (): TokenDecoded => {
  if (!localStorage.getItem('currentUser')) {
    return null;
  }
  return jwt_decode(localStorage.getItem('currentUser'));
};

export const lang = (): string => {
  return localStorage.getItem('lang');
};

export const createDownloadFile = (data, filename) => {
  const blob = new Blob([data], { type: data.type });
  const objectUrl: string = URL.createObjectURL(blob);
  const a: HTMLAnchorElement = document.createElement(
    'a',
  ) as HTMLAnchorElement;

  a.href = objectUrl;
  a.download = filename;
  document.body.appendChild(a);
  a.click();

  document.body.removeChild(a);
  URL.revokeObjectURL(objectUrl);
};

export async function urlToFile(
  url: string,
  filename: string,
): Promise<File> {
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('currentUser')}`,
    },
  });

  const blob = await response.blob();
  return new File([blob], filename, {
    type: blob?.type,
  });
}

type Lang = 'th' | 'en';

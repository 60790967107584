import {
  Directive,
  OnInit,
  OnDestroy,
  Input,
  ElementRef,
} from '@angular/core';
import { NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Directive({
  selector: 'input[ngbDatepicker][appDateBuddhistYear]',
})
export class DateBuddhistYearDirective implements OnInit, OnDestroy {
  @Input() appDateBuddhistYear: any;

  private subscription: Subscription;
  private currentLang: string;
  private isProcessing = false;

  constructor(
    private datepicker: NgbInputDatepicker,
    private translateService: TranslateService,
    private elementRef: ElementRef,
  ) {
    this.currentLang = this.translateService.currentLang;
  }

  ngOnInit() {
    if (!this.shouldApplyDirective()) return;
    this.subscription = this.translateService.onLangChange.subscribe(
      (event) => {
        this.currentLang = event.lang;
        this.updateDisplay();
      },
    );

    setTimeout(() => {
      this.updateDisplay();
    }, 0);

    const originalWriteValue = this.datepicker.writeValue;
    this.datepicker.writeValue = (value: any) => {
      originalWriteValue.call(this.datepicker, value);

      if (!this.isProcessing) {
        setTimeout(() => {
          this.updateDisplay();
        }, 0);
      }
    };
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private updateDisplay() {
    if (!this.shouldApplyDirective()) return;

    if (this.isProcessing) return;
    this.isProcessing = true;

    try {
      const isThai = this.currentLang === 'th';
      const input = this.elementRef.nativeElement as HTMLInputElement;
      if (!input.value) {
        this.isProcessing = false;
        return;
      }

      const dateValue = input.value;
      const matches = dateValue.match(/(\d{2})\/(\d{2})\/(\d{4})/);

      if (matches && matches.length === 4) {
        const day = matches[1];
        const month = matches[2];
        const year = parseInt(matches[3], 10);

        if (isThai && year < 2500) {
          const buddhistYear = year + 543;
          input.value = `${day}/${month}/${buddhistYear}`;
        } else if (!isThai && year > 2500) {
          const christianYear = year - 543;
          input.value = `${day}/${month}/${christianYear}`;
        }
      }
    } finally {
      this.isProcessing = false;
    }
  }
  private shouldApplyDirective(): boolean {
    if (typeof this.appDateBuddhistYear === 'boolean') {
      return this.appDateBuddhistYear;
    } else if (typeof this.appDateBuddhistYear === 'string') {
      return this.appDateBuddhistYear !== 'false';
    }
    return true;
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChange,
} from '@angular/core';
import { forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CertificateStatus } from '../../../../../certificate/shared/certificate.model';
import { ProfileService } from '../../../../../profile/shared/profile.service';
import { AlertService } from '../../../../../../core/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { featureFlag } from '../../../../../../../environments/environment';

@Component({
  selector: 'app-signing-law-section-radio',
  templateUrl: './signing-law-section-radio.component.html',
  styleUrls: ['./signing-law-section-radio.component.scss'],
})
export class SigningLawSectionRadioComponent implements OnChanges {
  @Input() signerId: number;
  @Input() certStatusSec26: CertificateStatus;
  @Input() certStatusSec28: CertificateStatus;
  @Input() ddocLawSection: DdocLawSectionObj;
  @Input() freeLawSelection = false;
  @Input() allowLOAChange = true;
  @Output() ddocLawSectionChange =
    new EventEmitter<DdocLawSectionObj>();
  @Output() signerRejected = new EventEmitter<void>();

  @Input() departments: number[] = [];

  ddoc_enable_feature_26 = featureFlag.ddoc_enable_feature_26;
  ddoc_enable_feature_28 = featureFlag.ddoc_enable_feature_28;

  constructor(
    private profileService: ProfileService,
    private alert: AlertService,
    private translate: TranslateService,
  ) {}

  ngOnChanges(changes: { [input: string]: SimpleChange }): void {
    if (changes.signerId) {
      const signerId = changes.signerId.currentValue;
      if (signerId == null) {
        this.certStatusSec26 = null;
        this.certStatusSec28 = null;
        return;
      }
      if (typeof signerId !== 'number') {
        console.error('The `signerId` input is not number');
        return;
      }
      this.fetchCertificateStatus(changes.signerId.firstChange);
    }
    if (changes.departments) {
      this.fetchCertificateStatus(changes.departments.firstChange);
    }
  }

  fetchCertificateStatus(isFirstChange: boolean): void {
    const certStatusSec26$ =
      this.profileService.loadCertificateStatus(this.signerId, false);
    const certStatusSec28$ =
      this.profileService.loadCertificateStatus(
        this.signerId,
        false,
        {
          type: 'm28',
          departments: this.departments
            ? this.departments.join(',')
            : '',
        },
      );
    forkJoin([certStatusSec26$, certStatusSec28$]).subscribe({
      next: (certs) => {
        this.certStatusSec26 = certs[0].certificate_status;
        this.certStatusSec28 = certs[1].certificate_status;
        const allValid = certs.every(
          (cert) => cert?.certificate_status === 'valid',
        );
        /** Set default selection when certificate is valid */
        if (allValid && this.ddocLawSection == null) {
          this.ddocLawSectionChange.emit({
            lawSection: '26',
            isFirstChange,
          });
          return;
        }
        /** Apply null if selected cert is invalid */
        const selectedCertIsValid =
          (this.ddocLawSection.lawSection === '26' &&
            certs[0].certificate_status === 'valid') ||
          (this.ddocLawSection.lawSection === '28' &&
            certs[1].certificate_status === 'valid');
        if (!selectedCertIsValid) {
          if (
            this.allowLOAChange &&
            featureFlag.ddoc_enable_feature_26
          ) {
            this.ddocLawSectionChange.emit({
              lawSection: '26',
              isFirstChange,
            });
          } else if (this.ddocLawSection?.lawSection === '28') {
            const error = this.translate.instant(
              'CERTIFICATE.NOT-CREATED',
            );
            this.alert.error(error);
            this.signerRejected.emit();
          }
        }
      },
      error: (error) => {
        console.log(error);
        /** Apply null if getting cert has error */
        this.ddocLawSectionChange.emit(null);
      },
    });
  }

  onRadioClick(value: '26' | '28') {
    // check if radio is disabled.
    let radioDivDisabled = false;
    if (value == '26') {
      radioDivDisabled = !this.allowLOAChange;
    }
    if (value == '28') {
      radioDivDisabled =
        (this.certStatusSec28 !== 'valid' &&
          !this.freeLawSelection) ||
        !this.allowLOAChange;
    }

    if (radioDivDisabled) {
      return;
    }

    // if radio is not disabled, fire change event
    if (this.ddocLawSection.lawSection != value) {
      this.onDdocLawSectionChange(value);
    }
  }

  onDdocLawSectionChange(value: '26' | '28'): void {
    // fire change event, parent component will change lawSection in this component.
    this.ddocLawSectionChange.emit({
      lawSection: value,
      isFirstChange: false,
    });
  }
}

export interface DdocLawSectionObj {
  lawSection: '26' | '28';
  isFirstChange?: boolean;
}
